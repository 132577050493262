import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation';

import styles from './Header.module.css';

const Header = ({ title }) => (
  <Fragment>
    <Navigation />

    <div className={styles.Header}>
      <h1>{title}</h1>
    </div>
  </Fragment>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;

import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={cx(styles.Footer)}>
      <Link to="/impressum">Impressum</Link>
      {/* 
      {' / '}
      <Link to="/datenschutz">Datenschutzerklärung</Link> */}
    </footer>
  );
};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './Navigation.module.css';

const Navigation = ({ className }) => {
  return (
    <nav className={styles.Navigation}>
      <div className={styles.bar}>
        <div className={styles.first} />
        <div className={styles.second} />
        <div className={styles.third} />
      </div>

      <ul>
        <li>
          <Link activeClassName={styles.activeClassName} to="/">
            Startseite
          </Link>
        </li>
        <li>
          <Link activeClassName={styles.activeClassName} to="/mannschaften">
            Mannschaften
          </Link>
        </li>
        <li>
          <Link activeClassName={styles.activeClassName} to="/verein">
            Über uns
          </Link>
        </li>
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
};

export default Navigation;

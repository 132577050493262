import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Section.module.css';

const Section = ({
  className,
  children,
  headline,
  fullWidth = false,
  inverse = false,
  neutral = false,
}) => {
  return (
    <section
      className={cx(styles.Section, className, {
        [styles.inverse]: inverse,
        [styles.neutral]: neutral,
      })}
    >
      {headline && <h2>{headline}</h2>}
      {React.Children.map(children, child => {
        if (!child.props) {
          return child;
        }
        return React.cloneElement(child, {
          ...child.props,
          className: cx(child.props.className, styles[child.type], {
            [`${styles[child.type]}FullWidth`]: fullWidth,
          }),
        });
      })}
    </section>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
  fullWidth: PropTypes.bool,
  inverse: PropTypes.bool,
  neutral: PropTypes.bool,
};

export default Section;

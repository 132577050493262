import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import './index.css';
import Footer from '../Footer';

const TemplateWrapper = ({ children, ...rest }) => (
  <div>
    <Helmet>
      <title>VC Teltow/Kleinmachnow</title>
      <meta
        name="description"
        content="Hier erfährst Du mehr über den VC Teltow/Kleinmachnow"
      />
      <meta
        name="keywords"
        content="Sport, Volleyball, Verein, Teltow, Kleinmachnow, Stahnsdorf"
      />

      <link
        href="https://fonts.googleapis.com/css?family=Cabin+Sketch|Orbitron"
        rel="stylesheet"
      />
    </Helmet>
    {children}
    <Footer />
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.node,
};

export default TemplateWrapper;
